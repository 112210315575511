<template>
    <!-- 学会介绍 -->
    <div class="introduce">
        <topHeader ind="1"></topHeader>
        <div class="intro_content">
            <el-tabs tab-position="left" style="min-height: 457px;">
                <el-tab-pane label="学会宗旨">
                    <div class="tab_nav">首页 > <span>学会介绍</span></div>
                    <div class="tab_text">
                        <div class="title">学会宗旨</div>
                        <div>促进河北省战略性新兴产业的繁荣和发展</div>
                        <div>促进河北省增材制造技术的普及和推广</div>
                        <div>促进增材制造技术人才的成长和提高</div>
                        <div>促进增材制造</div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="简要介绍">
                    <div class="tab_nav">首页 > <span>学会介绍</span></div>
                    <div class="tab_text">
                        <div class="title">简要介绍</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;河北省增材制造学会是由河北科技大学、河钢集团有限公司、华北理工大学、石家庄铁道大学、中航迈特粉冶科技（固安）有限公司联合发起成立，由从事增材制造技术研究与应用的个人、企事业单位和社会组织自愿结成的地方性学术团体和非营利性社会组织，是为促进河北省战略性新兴产业的繁荣和发展，促进河北省增材制造技术的普及和推广，促进增材制造技术人才的成长和提高，促进增材制造技术更好地服务我省经济，而打造的一个增材制造理论、技术与应用研究，增材制造学术交流与普及，增材制造咨询服务与政策建议平台。
                        </div>
                        <!-- <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;学会接受业务主管单位河北省科学技术协会与社团登记管理机关河北省民政厅的业务指导和监督管理，于2021年7月2日被河北省民政厅正式批准筹备成立，并于2022年3月12日成功召开成立大会暨第一届会员代表大会。学会现有个人会员165人，单位会员59个。</div> -->
                    </div>
                </el-tab-pane>
                <el-tab-pane label="组成人员">
                    <div class="tab_nav">首页 > <span>学会介绍</span></div>
                    <div class="tab_text" style="margin-top: 20px;">
                        <!-- <div class="title">组成人员</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;学会接受业务主管单位河北省科学技术协会与社团登记管理机关河北省民政厅的业务指导和监督管理，于2021年7月2日被河北省民政厅正式批准筹备成立，并于2022年3月12日成功召开成立大会暨第一届会员代表大会。学会现有个人会员165人，单位会员59个。
                        </div> -->
                        <div>
                            <div class="item" v-for="(item,i) in list" :key="i">
                                <div class="y_title">
                                    <div>{{item.name}}</div>
                                </div>
                                <div class="list">
                                    <div class="list_item" v-for="(it,k) in item.peoples" :key="k">
                                        <div></div>
                                        <div>{{ it }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import axios from '../http/axios';
    export default {
        data(){
            return{
                list:[]
            }
        },
        mounted(){
            axios.get('organizations').then(res=>{
                if(res.err_code == 200){
                   this.list = res.data
                }else{
                    this.$message.error(res.msg);
                }
            })
        },
    }
</script>

<style scoped lang='less'>
.item{
    width: 100%;
    height: auto;
    line-height: normal;
    letter-spacing: normal;
    font-size: inherit;
    margin-bottom: 0px;
    .list{
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: row wrap;
        padding: 21px 10px 14px 12px;
        box-sizing: border-box;
        .list_item{
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: auto;
            height: auto;
            margin-right:13px;
            margin-bottom: 18px;
            >div:first-child{
                width: 12px;
                height: 12px;
                border: 2px solid #FF5B06;
                margin-right: 8px;
                transform: rotate(45deg);
		        font-family: '' !important;
            }
            >div:last-child{
                width: 102px;
                height: auto;
                font-size: 18px;
                font-weight: 400;
                color: #000000;
                line-height: 24px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
		        font-family: '' !important;
            }
        }
    }
    .y_title{
        line-height: 23px;
        width: 100%;
        border-bottom: 1px solid #1D7FD7;
        >div{
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #FFFFFF;
            background: #1D7FD7;
            padding: 0 11px;
            width: auto;
            height: auto;
            display: inline-block;
		    font-family: '' !important;
        }
    }
}
.introduce{
    .intro_content{
        width: 1140px;
        margin: 20px auto 40px;
        /deep/.el-tabs__active-bar,/deep/.el-tabs--left .el-tabs__nav-wrap.is-left::after{
            display: none;
        }
        /deep/.el-tabs--left .el-tabs__header.is-left{
            margin-right: 26px;
        }
        /deep/.el-tabs__item{
            width: 270px;
            height: 52px;
            color: #999999;
            font-size: 16px;
            line-height: 52px;
            padding: 0 27px;
            text-align: left;
            border: 1px solid #EBEBEB;
        }
        /deep/.el-tabs__item+.el-tabs__item{
            border-top: 0;
        }
        /deep/.el-tabs__item.is-active{
            background-color:#1D7FD7;
            color: #ffffff;
        }
        .tab_nav{
            font-size: 16px;
            padding-bottom: 8px;
            border-bottom: 2px solid #1D7FD7;
            span{
                color: #1D7FD7;
            }
        }
        .tab_text{
            margin-top: 28px;
            .title{
                font-size: 24px;
                color: #1D7FD7;
                line-height: 24px;
                margin-bottom: 19px;
            }
            >div{
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 22px;
                letter-spacing: 2px;
            }
        }
    }
}
</style>